import * as React from 'react';
import titleTemplate from 'lib/title-template';
import Head from 'next/head';
import Page from 'components/common/page';
import { useState } from 'react';
import { useRouter } from 'next/router';
import { FailedSsoCode, LoginCode } from 'api/lib/auth-codes';
import Link from 'next/link';
import { useAppDispatch } from 'redux/store';
import { login } from 'redux/modules/auth-module';
import { LabelMedium, LabelSmall } from 'components/common/typography';
import Button from 'components/common/button';
import GoogleSVG from 'static/svgs/google.svg';
import AppleSVG from 'static/svgs/apple.svg';
import makeSsoUrl from 'lib/make-sso-url';
import { getSharingInvitation } from 'models/citation-list-model';
import css from './user-auth-pages.module.css';

const failedSsoMessages: Record<FailedSsoCode, string> = {
  missing_email: 'An email address is required to continue.',
  unknown: 'Something went wrong.',
};
const failedLoginMessages: Record<LoginCode, string> = {
  combination_incorrect: 'That email and password combination is incorrect.',
  invalid_input: 'Please enter both email and password.',
  only_sso:
    'This account can only be logged into with Google or Apple, or by resetting the password with "Forgot password".',
};

const Login = (): JSX.Element => {
  const [email, setEmail] = useState<string | null>(null);
  const [password, setPassword] = useState<string | null>(null);
  const router = useRouter();
  const queryErrorCode = router.query.error as FailedSsoCode;
  const required =
    router.query.required && 'You need to be logged in to see this page.';
  const initialMessage = failedSsoMessages[queryErrorCode] || required || null;
  const [stateMessage, setStateMessage] = useState<string | null>(null);
  const [invitationEmail, setInvitationEmail] = useState<string | null>(null);
  const [invitationListName, setInvitationListName] = useState<string | null>(
    null,
  );
  const message = stateMessage || initialMessage;
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    async function getListSharerDetails(): Promise<void> {
      if (router.query.next) {
        const shareId = Array.isArray(router.query.next)
          ? router.query.next.pop()
          : router.query.next.split('/').pop();
        if (shareId) {
          const sharerData = await getSharingInvitation(shareId);
          setInvitationEmail(sharerData.data.owner);
          setInvitationListName(sharerData.data.listName);
        }
      }
    }
    getListSharerDetails();
  }, [router.query.next]);

  const handleSubmit = async (event: React.MouseEvent): Promise<void> => {
    event.preventDefault();
    if (!email || !password) {
      return;
    }
    try {
      await dispatch(login({ email, password })).unwrap();
      router.push((router.query.next as string) || '/');
    } catch (error: any) {
      setStateMessage(
        failedLoginMessages[(error as { code: LoginCode })?.code] ||
          'Something went wrong.',
      );
      return;
    }
  };
  return (
    <Page pageName="Login">
      <Head>
        <title>{titleTemplate('Log in')}</title>
        <meta
          name="description"
          content="Log into your Bibcitation account to manage your citation lists, add references and share bibliographies with others."
        />
      </Head>
      <div className={css.mainWrapper}>
        <div className={css.formContainer}>
          {message != initialMessage && (
            <div className={css.errorMessage}>{message}</div>
          )}
          <form>
            {(!router.query.next || !invitationListName) && (
              <h1 className={css.heading}>
                <LabelMedium>Log in</LabelMedium>
              </h1>
            )}
            {router.query.next && invitationListName && (
              <>
                <h1 className={css.heading}>
                  <LabelMedium>
                    Log in to join {'"' + invitationListName + '"'}
                  </LabelMedium>
                </h1>
                <h3 className={css.headingDetails}>
                  {invitationEmail} invited you to cooperate on the citation
                  list. Log in or create an account to continue
                </h3>
              </>
            )}
            <label className={css.label} htmlFor="email">
              <LabelSmall>Email</LabelSmall>
            </label>
            <input
              type="email"
              id="email"
              onChange={(event) => setEmail(event.target.value)}
            />
            <label className={css.label} htmlFor="password">
              <LabelSmall>Password</LabelSmall>
            </label>
            <input
              type="password"
              id="password"
              onChange={(event) => setPassword(event.target.value)}
            />
            <div className={css.formContainerButtons}>
              <div className={css.buttonWrapper}>
                <Button fullWidth onClick={handleSubmit}>
                  Log in
                </Button>
              </div>
              <div className={css.buttonWrapper}>
                <Button
                  fullWidth
                  type="white"
                  href={makeSsoUrl(router.query.next, 'google', 'login')}
                >
                  <>
                    <span className={css.buttonIcon}>
                      <GoogleSVG />
                    </span>
                    Continue with Google
                  </>
                </Button>
              </div>
              <div className={css.buttonWrapper}>
                <Button
                  fullWidth
                  type="white"
                  href={makeSsoUrl(router.query.next, 'apple', 'login')}
                >
                  <>
                    <span className={css.buttonIcon}>
                      <AppleSVG />
                    </span>
                    Continue with Apple
                  </>
                </Button>
              </div>
            </div>
          </form>
          <div className="links">
            <div className={css.linksItem}>
              <Link href="/forgot-password">Forgot password?</Link>
            </div>
            <div className={css.linksItem}>
              <Link
                href={`/signup?next=${encodeURIComponent(
                  (router.query.next as string) || '/',
                )}`}
              >
                Sign up
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};
export default Login;
