import createApiUrl from './create-api-url';

const makeSsoUrl = (
  next: string | string[] | undefined = '/',
  provider: 'apple' | 'google',
  flow: 'login' | 'signup',
): string =>
  createApiUrl(
    `/auth/${provider}?flow=${flow}&next=${encodeURIComponent(String(next))}`,
  );

export default makeSsoUrl;
